import * as React from 'react';
import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { PageLayout } from '../../ui';

export const ErrorLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <PageLayout footer={null}>
      <Box width="100%" alignItems="center" mt="space4" mb="space2">
        <MentimeterSymbol width={40} height={40} />
      </Box>
      {children}
    </PageLayout>
  );
};
