import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import Footer from '../Components/Footer/Footer';

export interface PropsT {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const PageLayout = ({ children, footer = <Footer /> }: PropsT) => {
  return (
    <Box
      width="100%"
      maxWidth={['100vw', '568px']}
      flex="1 1 auto"
      alignItems="stretch"
      pt={['space8', 'space16']}
      m="0 auto"
    >
      <Box px="space10" flex="1 0 auto" alignItems="center" as="main">
        {children}
      </Box>

      {/* Static footer */}
      {Boolean(footer) && (
        <Box justifyContent="center" width="100%">
          {footer}
        </Box>
      )}
    </Box>
  );
};
