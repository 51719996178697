import * as React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { isLight } from '@mentimeter/ragnar-colors';
import { Svg } from '../svg';

export interface LogoTypeT {
  variant?: 'primary' | 'mono';
  height?: number | string;
  width?: number | string;
}

export interface WordmarkT {
  height?: number | string;
  width?: number | string;
}

const SymbolPrimary = () => {
  const { theme } = useRagnar();

  return (
    <g
      fill={
        isLight(theme.colors.bg)
          ? 'var(--palette-black-base)'
          : 'var(--palette-white-base)'
      }
      className="light"
    >
      <path
        d="M739.215 0V669.43H469.129C469.129 550.498 416.924 443.718 334.086 370.204V267.863H469.129V133.886H604.172V0H739.215Z"
        fill="var(--palette-blue-500)"
      />
      <path
        d="M469.053 669.423C469.053 447.635 287.704 267.84 64 267.84V669.423H469.053Z"
        fill="var(--palette-coral-500)"
      />
      <path d="M333.849 370.007C262.328 306.605 167.997 267.977 64.5425 267.848H64V0H199.043L334.086 267.863V370.204C334.017 370.143 333.926 370.075 333.849 370.007Z" />
    </g>
  );
};

const SymbolMono = () => {
  const { theme } = useRagnar();

  return (
    <g
      fill={
        isLight(theme.colors.bg)
          ? 'var(--palette-black-base)'
          : 'var(--palette-white-base)'
      }
    >
      <path d="M449.088 669.43C449.088 456.799 276.631 284.342 64 284.342V669.43H449.088Z" />
      <path d="M331.572 370.07C260.611 306.619 167.124 268.072 64.5006 267.947H64V0H197.911L331.822 267.822V370.195C331.822 370.195 331.697 370.07 331.572 369.945" />
      <path d="M348.227 267.822H465.618V133.786H599.529V0H733.44V669.43H465.618C465.618 558.672 420.689 458.551 348.227 385.839V267.822Z" />
    </g>
  );
};

const Wordmark = () => {
  const { theme } = useRagnar();
  return (
    <g
      fill={
        isLight(theme.colors.bg)
          ? 'var(--palette-black-base)'
          : 'var(--palette-white-base)'
      }
    >
      <path d="M1797.7,0V669.45H1692.5V58.34L1536.62,669.45H1332.91L1178,58.34V669.45H1072.79V0h203.7L1436.2,629.28,1595.91,0Z" />
      <path d="M2329.46,438l-382.54,55.47c22,55.47,74.6,92.76,137.72,92.76,64.07,0,113.8-34.42,137.71-87l92.77,31.56C2277.82,616.85,2190.79,679,2083.68,679,1943.1,679,1836,570.94,1836,434.18c0-137.71,107.11-244.82,247.69-244.82S2331.37,294.56,2329.46,438Zm-115.72-65c-21-64.07-66-91.81-130.06-91.81-81.29,0-137.71,54.51-147.28,130.06Z" />
      <path d="M2794.27,362.46v307H2692.9V386.37c0-74.6-30.61-105.2-95.64-105.2-74.59,0-128.15,47.82-128.15,131V669.45H2367.74V198.92h101.37v67.9c30.61-51.64,87-77.46,151.11-77.46C2720.63,189.36,2794.27,249.61,2794.27,362.46Z" />
      <path d="M2992.25,198.92h146.32v82.25H2992.25v247.7c0,41.12,26.79,59.29,63.13,59.29,24.86,0,47.81-5.74,70.77-17.22v89.9c-21,9.56-58.34,17.21-85.12,17.21-101.38,0-150.15-47.81-150.15-149.18V281.17H2823V198.92h67.9V83.2L2992.25,5.74Z" />
      <path d="M3171.11,79.38a62.16,62.16,0,1,1,62.16,62.16A62,62,0,0,1,3171.11,79.38Zm13.39,119.54h101.37V669.45H3184.5Z" />
      <path d="M4014.6,356.72V669.45H3913.22V369.16c0-62.17-28.68-88-80.33-88s-104.24,41.13-104.24,115.72V669.45H3627.28V369.16c0-62.17-28.7-88-80.34-88S3442.7,322.3,3442.7,397.84V669.45H3341.33V198.92H3442.7V263c27.73-48.78,75.55-73.64,131-73.64,59.3,0,111.9,26.78,134.85,85.11,32.51-52.59,84.16-85.11,151.1-85.11C3946.7,189.36,4014.6,247.7,4014.6,356.72Z" />
      <path d="M4546.36,438l-382.54,55.47c22,55.47,74.6,92.76,137.72,92.76,64.07,0,113.8-34.42,137.71-87L4532,530.78C4494.72,616.85,4407.69,679,4300.58,679c-140.58,0-247.69-108.07-247.69-244.83,0-137.71,107.11-244.82,247.69-244.82S4548.28,294.56,4546.36,438Zm-115.72-65c-21-64.07-66-91.81-130.06-91.81-81.29,0-137.71,54.51-147.28,130.06Z" />
      <path d="M4716.62,198.92h146.32v82.25H4716.62v247.7c0,41.12,26.78,59.29,63.12,59.29,24.86,0,47.82-5.74,70.77-17.22v89.9c-21,9.56-58.34,17.21-85.12,17.21-101.37,0-150.15-47.81-150.15-149.18V281.17h-67.9V198.92h67.9V83.2L4716.62,5.74Z" />
      <path d="M5365,438l-382.54,55.47c22,55.47,74.6,92.76,137.71,92.76,64.08,0,113.81-34.42,137.72-87l92.76,31.56C5313.39,616.85,5226.36,679,5119.25,679c-140.58,0-247.69-108.07-247.69-244.83,0-137.71,107.11-244.82,247.69-244.82S5367,294.56,5365,438Zm-115.72-65c-21-64.07-66-91.81-130.06-91.81-81.29,0-137.72,54.51-147.28,130.06Z" />
      <path d="M5663.44,191.28V284a229.09,229.09,0,0,0-35.39-2.87c-74.6,0-123.37,49.73-123.37,135.8V669.45H5403.31V198.92h99.46V269.7c22-48.78,70.77-80.34,132-80.34C5647.17,189.36,5658.65,190.31,5663.44,191.28Z" />
    </g>
  );
};

const WordmarkStacked = () => {
  const { theme } = useRagnar();

  return (
    <g
      fill={
        isLight(theme.colors.bg)
          ? 'var(--palette-black-base)'
          : 'var(--palette-white-base)'
      }
    >
      <path d="M129,734.46V851.33H110.66V744.65L83.45,851.33H47.89l-27-106.68V851.33H2.48V734.46H38L65.92,844.32,93.8,734.46Z" />
      <path d="M221.86,810.93l-66.78,9.68a25.52,25.52,0,0,0,24,16.2c11.18,0,19.86-6,24-15.2l16.19,5.51c-6.51,15-21.7,25.88-40.4,25.88-24.54,0-43.24-18.87-43.24-42.74,0-24,18.7-42.74,43.24-42.74S222.19,785.89,221.86,810.93Zm-20.2-11.35c-3.68-11.19-11.52-16-22.71-16-14.19,0-24,9.51-25.71,22.7Z" />
      <path d="M303,797.74v53.59H285.3V801.91c0-13-5.34-18.36-16.69-18.36-13,0-22.37,8.35-22.37,22.87v44.91h-17.7V769.19h17.7V781c5.34-9,15.19-13.52,26.37-13.52C290.14,767.52,303,778,303,797.74Z" />
      <path d="M337.56,769.19H363.1v14.36H337.56v43.24c0,7.18,4.68,10.35,11,10.35a26.87,26.87,0,0,0,12.35-3v15.7a42,42,0,0,1-14.85,3c-17.7,0-26.22-8.34-26.22-26V783.55H308V769.19h11.85V749l17.7-13.52Z" />
      <path d="M368.78,748.32a10.86,10.86,0,1,1,10.86,10.85A10.83,10.83,0,0,1,368.78,748.32Zm2.34,20.87h17.7v82.14h-17.7Z" />
      <path d="M516,796.74v54.59H498.34V798.91c0-10.85-5-15.36-14-15.36s-18.2,7.18-18.2,20.2v47.58H448.42V798.91c0-10.85-5-15.36-14-15.36s-18.19,7.18-18.19,20.37v47.41H398.5V769.19h17.7v11.19c4.84-8.52,13.19-12.86,22.87-12.86,10.35,0,19.53,4.68,23.54,14.86,5.67-9.18,14.69-14.86,26.38-14.86C504.18,767.52,516,777.71,516,796.74Z" />
      <path d="M608.86,810.93l-66.78,9.68a25.52,25.52,0,0,0,24,16.2c11.19,0,19.87-6,24-15.2l16.2,5.51c-6.51,15-21.7,25.88-40.4,25.88-24.54,0-43.24-18.87-43.24-42.74,0-24,18.7-42.74,43.24-42.74S609.2,785.89,608.86,810.93Zm-20.2-11.35c-3.67-11.19-11.52-16-22.7-16-14.19,0-24,9.51-25.71,22.7Z" />
      <path d="M638.58,769.19h25.55v14.36H638.58v43.24c0,7.18,4.68,10.35,11,10.35a26.94,26.94,0,0,0,12.36-3v15.7a42.1,42.1,0,0,1-14.86,3c-17.7,0-26.21-8.34-26.21-26V783.55H609V769.19h11.86V749l17.69-13.52Z" />
      <path d="M751.78,810.93,685,820.61a25.52,25.52,0,0,0,24,16.2c11.19,0,19.87-6,24-15.2l16.19,5.51c-6.51,15-21.7,25.88-40.4,25.88-24.54,0-43.24-18.87-43.24-42.74,0-24,18.7-42.74,43.24-42.74S752.11,785.89,751.78,810.93Zm-20.2-11.35c-3.68-11.19-11.52-16-22.71-16-14.19,0-24,9.51-25.71,22.7Z" />
      <path d="M803.87,767.86v16.19a40.59,40.59,0,0,0-6.18-.5c-13,0-21.53,8.68-21.53,23.71v44.07h-17.7V769.19h17.36v12.36c3.84-8.52,12.36-14,23-14A31.07,31.07,0,0,1,803.87,767.86Z" />
    </g>
  );
};

export function MentimeterSymbol({ variant = 'primary', ...rest }: LogoTypeT) {
  return (
    <Svg viewBox="0 0 803.87 676.39" aria-label="Mentimeter Logo" {...rest}>
      {variant === 'mono' ? <SymbolMono /> : <SymbolPrimary />}
    </Svg>
  );
}

export function MentimeterWordmark(props: WordmarkT) {
  return (
    <Svg viewBox="1050 0 4700 699.5" {...props}>
      <Wordmark />
    </Svg>
  );
}

export function MentimeterLogoType({
  variant = 'primary',
  ...rest
}: LogoTypeT) {
  return (
    <Svg viewBox="0 0 5663.44 679.01" aria-label="Mentimeter Logo" {...rest}>
      {variant === 'mono' ? <SymbolMono /> : <SymbolPrimary />}

      <Wordmark />
    </Svg>
  );
}

export function MentimeterLogoTypeStacked({
  variant = 'primary',
  ...rest
}: LogoTypeT) {
  return (
    <Svg viewBox="0 0 803.87 853" aria-label="Mentimeter Logo" {...rest}>
      {variant === 'mono' ? <SymbolMono /> : <SymbolPrimary />}

      <WordmarkStacked />
    </Svg>
  );
}
