import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui/box';
import { Link, type LinkT } from '@mentimeter/ragnar-ui/link';
import { Text, type TextT } from '@mentimeter/ragnar-ui/text';

const wwwUrl = process.env.NEXT_PUBLIC_WWW_URL;

export const FooterContainer = ({ children }: PropsWithChildren<object>) => {
  return (
    <Box mt="space6" width="100%" alignItems="center">
      <Box
        as="footer"
        flexDirection="column"
        width="100%"
        alignItems="center"
        gap={2}
      >
        {children}
      </Box>
    </Box>
  );
};

FooterContainer.TermsContainer = (props: BoxT) => (
  <Box
    width="100%"
    bg="surfaceSunken"
    height="48px"
    alignItems="center"
    justifyContent="center"
    {...props}
  />
);
FooterContainer.Text = (props: TextT) => <Text textAlign="center" {...props} />;
FooterContainer.TermsText = (props: TextT) => (
  <Text textAlign="center" fontSize="87.5" {...props} />
);

FooterContainer.TermsOfUseLink = (props: LinkT) => (
  <Link
    href={`${wwwUrl}/terms`}
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  />
);
FooterContainer.PoliciesLink = (props: LinkT) => (
  <Link href="/policies" target="_blank" rel="noopener noreferrer" {...props} />
);
