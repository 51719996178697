import * as React from 'react';
import { FooterContainer } from './FooterContainer';

export const Terms = () => {
  return (
    <FooterContainer.TermsContainer>
      <FooterContainer.TermsText fontSize="75">
        By using Mentimeter you accept our{' '}
        <FooterContainer.TermsOfUseLink>
          terms of use
        </FooterContainer.TermsOfUseLink>{' '}
        and{' '}
        <FooterContainer.PoliciesLink>policies</FooterContainer.PoliciesLink>.
      </FooterContainer.TermsText>
    </FooterContainer.TermsContainer>
  );
};
