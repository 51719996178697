import * as React from 'react';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import { Button } from '@mentimeter/ragnar-ui/button';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { useTranslate } from '../../features/localization/Translate';

export interface NavigationButtonProps extends ButtonT {
  onClick: React.MouseEventHandler<Element>;
  isLastSlide?: boolean;
}

export const NavigationButtonExperiment = ({
  onClick,
  isLastSlide = true,
}: NavigationButtonProps) => {
  // TODO: Split Migration
  const OG_Menti_Finish_Button_To_Endscreen: string = 'v1';
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(e);
  };

  if (['v2', 'v3'].includes(OG_Menti_Finish_Button_To_Endscreen)) {
    return (
      <Button
        size="large"
        variant="primary"
        width={1}
        mt="space4"
        onClick={onClickHandler}
      >
        {OG_Menti_Finish_Button_To_Endscreen === 'v2' ? 'Continue' : 'Next'}
      </Button>
    );
  }

  return (
    <NavigationButton onClick={onClickHandler} isLastSlide={isLastSlide} />
  );
};

export const NavigationButton = ({
  onClick,
  isLastSlide = true,
}: NavigationButtonProps) => {
  const translate = useTranslate();

  return (
    <Button
      size="large"
      iconTrailing={ChevronRightIcon}
      variant="subtle"
      mt="space4"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {isLastSlide ? translate('buttons.finish') : translate('buttons.next')}
    </Button>
  );
};
